import Vue from "vue";

export default class BannersService {
  static baseUrl = "banner";

  static create(payload = {}) {
    return Vue.axios.post(this.baseUrl, payload);
  }

  static show(id) {
    return Vue.axios.get(`${this.baseUrl}/${id}`);
  }

  static update(payload = {}, id) {
    return Vue.axios.put(`${this.baseUrl}/${id}`, payload);
  }

  static changeStatus(status, id) {
    return Vue.axios.put(`${this.baseUrl}/${id}/change-status`, { status });
  }

  static destroy(id) {
    return Vue.axios.delete(`${this.baseUrl}/${id}`);
  }

  static getDatatableUrl(bannersSectionId) {
    return `${this.baseUrl}/${bannersSectionId}/list`;
  }
}
