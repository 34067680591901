<template>
  <div>
    <base-header type="gradient-green" class="pb-6 pb-8 pt-5 pt-md-8" />
    <b-container class="mt--7" fluid>
      <b-row>
        <b-col md="3" class="mb-5 mb-md-0">
          <b-list-group>
            <b-list-group-item
              v-for="(section, idx) in bannerSections"
              :key="idx"
              @click.prevent="currentBannerSection = section"
              class="cursor-pointer"
              :active="currentBannerSection.id === section.id"
            >
              {{ section.name }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col md="8" offset-md="1">
          <card>
            <div v-if="currentBannerSection.id">
              <b>&raquo; {{ currentBannerSection.name }}</b>
              <hr />
              <datatable
                classe="table table-bordered table-striped"
                :url="datatableUrl"
                ref="datatable"
                :btnExport="false"
                @create="
                  $router.push({
                    name: 'banners-create',
                    params: {
                      section_id: currentBannerSection.id,
                    },
                  })
                "
              >
                <div slot-scope="{ actions }">
                  <b-dropdown boundary="viewport" size="sm" text="Actions" variant="primary">
                    <b-dropdown-item
                      @click="
                        $router.push({
                          name: 'banners-edit',
                          params: { id: actions.id, section_id: currentBannerSection.id },
                        })
                      "
                    >
                      <i class="fa fa-edit p-1"></i> Edit
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click.prevent="changeStatus(actions.status === 'E' ? 'D' : 'E', actions.id)"
                    >
                      <i class="fa fa-toggle-on p-1"></i> Change Status
                    </b-dropdown-item>
                    <b-dropdown-item @click.prevent="dropItem(actions.id)">
                      <i class="fa fa-trash p-1 text-danger"></i> Delete
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
                <template #status="{ data }">
                  <small :class="'badge badge-' + (data == 'E' ? 'primary' : 'danger')">
                    {{ data == "E" ? "Enabled" : "Disabled" }}
                  </small>
                </template>
                <template #image="{ data }">
                  <img :src="resource(data)" width="70" class="rounded" :alt="data" />
                </template>
              </datatable>
            </div>

            <div class="text-center py-5" v-else>
              <div v-if="loading">
                <b-spinner />
                <h1>Loading...</h1>
              </div>
              <div v-else>
                <i class="fa fa-warning"></i>
                <h1>Select one section to view banners</h1>
              </div>
            </div>
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import BannersService from "features/banners/services/BannersService";
import BannerSectionService from "features/banners/services/BannerSectionService";
import { resource } from "helpers";

export default {
  name: "Banners",
  data: () => ({
    resource,
    bannerSections: [],
    currentBannerSection: {
      id: "",
      name: "",
    },
    loading: false,
  }),
  mounted() {
    this.getBannerSections();
  },
  computed: {
    datatableUrl() {
      return this.currentBannerSection.id
        ? BannersService.getDatatableUrl(this.currentBannerSection.id)
        : "";
    },
  },
  watch: {
    datatableUrl(value) {
      this.$nextTick(() => {
        if (value && this.$refs?.datatable !== null) {
          this.$refs.datatable.refresh();
        }
      });
    },
  },
  methods: {
    getBannerSections() {
      this.loading = true;
      BannerSectionService.list()
        .then(({ data }) => {
          this.bannerSections = data;
          this.currentBannerSection = data[0] ?? {
            id: "",
            name: "",
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeStatus(status, id) {
      this.$swal({
        title: "Are you sure?",
        text: "This action changes the item status.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          BannersService.changeStatus(status, id).then(() => {
            this.$refs.datatable.refresh();
            this.$swal("Changed!", "Status changed", "success");
          });
        }
      });
    },
    dropItem(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Do you want to delete this item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          BannersService.destroy(id).then(() => {
            this.$refs.datatable.refresh();
            this.$swal("Dropped!", "Deleted item", "success");
          });
        }
      });
    },
  },
};
</script>
